import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import DataProvider from '@/api/DataProvider';
import httpClient from '@/api/httpClient';
import store from '@/store';
import resources from './resources';

const baseURL = getEnvVariable('VUE_APP_INTEGRATIONS_PARTNERS_V2_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
            'X-Quext-Community-Id': store.getters['auth/community']?.id,
        },
    };
};

const afterRequestInterceptor = ({ response }) => {
    const code = response?.data?.statusCode;
    if (code && `${code}`[0] !== '2') throw new Error(response?.data?.message);
    return response?.data;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, afterRequestInterceptor);
export default new DataProvider(httpAdapter);
