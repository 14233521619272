import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_LEASING_SERVICE_URL');

const actions = ['get', 'getOne', 'getList', 'create'];

const moveInResources = ['getAllLeases', 'getAllAccounting', 'getOccupancy'];

const beforeRequestInterceptor = ({ requestConfig, action, resource }) => {
    const commonHeaders = {
        'X-Quext-Customer-Id': store.getters['auth/customerId'],
        'X-Quext-Community-Id': store.getters['auth/community']?.id,
    };
    const requestHeaders = requestConfig.headers || {};

    if (actions.includes(action) && moveInResources.includes(resource)) {
        return {
            ...requestConfig,
            responseType: requestConfig.params.responseType,
            headers: { ...requestConfig.params.headers, ...commonHeaders },
        };
    }

    return {
        ...requestConfig,
        headers: {
            ...requestHeaders,
            ...commonHeaders,
        },
    };
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, ({ response }) => response);

export default new DataProvider(httpAdapter);
